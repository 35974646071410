import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby"

export default function LangSwitch(props) {    
    const [showLangMenu, setShowLangMenu] = useState(false);
    return (
        <div className="block">
            <div className="inline relative">

                <StaticQuery
                    query={graphql`
                        query LanguageQuery {

                            allDatoCmsSiteSetting {
                                edges {
                                    node {
                                        languageName
                                        locale
                                        languageFlag {
                                            url
                                          }
                                    }
                                }
                            }

                            datoCmsSiteSetting {
                                languageName
                            }
                        }
                    `}
                    render={data => (
                        <>
                            <button onClick={() => setShowLangMenu(!showLangMenu)} type="button" className="inline-flex items-center relative p-0 mt-0 md:mt-0 lg:px-2 border-0 lg:border border-gray-200 rounded-full lg:bg-white bg-gray-100 hover:shadow-lg focus:outline-none focus:shadow-outline transition duration-150 ease-in-out w-10 h-10 lg:w-auto lg:h-auto">

                                <div className="lg:pl-2 lg:pr-3 pl-0 text-gray-500 lg:text-gray-400 text-center mx-auto text-sm font-bold">
                                    <div className="hidden lg:block">
                                        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ display: 'block', height: '20px', width: '20px', fill: 'currentcolor' }}><path d="m8.002.25a7.77 7.77 0 0 1 7.748 7.776 7.75 7.75 0 0 1 -7.521 7.72l-.246.004a7.75 7.75 0 0 1 -7.73-7.513l-.003-.245a7.75 7.75 0 0 1 7.752-7.742zm1.949 8.5h-3.903c.155 2.897 1.176 5.343 1.886 5.493l.068.007c.68-.002 1.72-2.365 1.932-5.23zm4.255 0h-2.752c-.091 1.96-.53 3.783-1.188 5.076a6.257 6.257 0 0 0 3.905-4.829zm-9.661 0h-2.75a6.257 6.257 0 0 0 3.934 5.075c-.615-1.208-1.036-2.875-1.162-4.686l-.022-.39zm1.188-6.576-.115.046a6.257 6.257 0 0 0 -3.823 5.03h2.75c.085-1.83.471-3.54 1.059-4.81zm2.262-.424c-.702.002-1.784 2.512-1.947 5.5h3.904c-.156-2.903-1.178-5.343-1.892-5.494l-.065-.007zm2.28.432.023.05c.643 1.288 1.069 3.084 1.157 5.018h2.748a6.275 6.275 0 0 0 -3.929-5.068z"></path></svg>
                                        {/* <img src={props.currentFlag.url} className="block h-6 w-6 lg:h-4 lg:w-4" /> */}
                                    </div>
                                    <span className="uppercase lg:hidden">{props.currentLocale}</span>
                                </div>
                                <div className="lg:border-l lg:border-gray-200 justify-center flex-grow-0 flex-shrink-0 h-10 w-auto px-5 hidden lg:flex">
                                    <span className="flex self-center text-gray-700 uppercase text-xs">{props.currentLang}</span>
                                </div>
                            </button>

                {showLangMenu &&

                    <div className="absolute right-0 mt-2 w-48 border border-gray-200 bg-white rounded-lg shadow-md z-20 text-left">
                        <div className="rounded-lg bg-white shadow-xs overflow-hidden">

                        {data.allDatoCmsSiteSetting.edges.filter(e => e.node.languageName !== props.currentLang).map(language => {
                        
                            return(

                            <Link key={language.node.languageName} to={language.node.locale === 'en' ? '/' : `/${language.node.locale}`} className="block px-4 py-2 border-b capitalize text-gray-700 hover:bg-gray-100 hover:text-blue-800">
                                 <span className="">
                                    <span className="uppercase inline-block w-6 h-4 text-xs text-gray-400 border-r mr-2 pr-2">{language.node.locale}</span>
                                    <span className="text-xs uppercase">{language.node.languageName}</span>
                                </span>
                            </Link>

                            )
                        })}

                        </div>
                    </div>
                }

            </>

            )}
            />
            </div>
        </div>
    )
}
export const query = graphql`
  fragment CurrentLangFragment on DatoCmsSiteSetting {
      locale
      languageName
      languageFlag {
        url
      }
  }
  `