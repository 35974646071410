import React from "react"
import { graphql } from "gatsby"
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Img from 'gatsby-image';

export default function Stories(
    {
        data: {
            stories,
            storiesHeading
        }
    }
) {


    return (

        <section className="bg-white pt-8 md:pt-16" id="section-voices">
            <div className="container max-w-5xl mx-auto px-0 sm:px-4">

                <h2 className="px-4 text-center mt-2 ax-text-blue text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 mb-10">
                    {storiesHeading}
                </h2>


                {stories.map(story => {

                const [openModal, setOpenModal] = React.useState(false);

                    return (
                        <section className="bg-white overflow-hidden" key={story.id}>
                            <div className="relative max-w-screen-xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
                                <svg className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                                    <defs>
                                        <pattern id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="784" height="404" fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
                                </svg>

                                <svg className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                                    <defs>
                                        <pattern id="56409614-3d62-4985-9a10-7ca758a8f4f0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="404" height="784" fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
                                </svg>

                                <div className="story relative lg:flex lg:items-center">
                                    <div className="hidden lg:block lg:flex-shrink-0">
                                        {/* <img className="h-64 w-64 rounded-full object-cover xl:h-80 xl:w-80 border-8" src={Singapore} alt="Singapore team standing on a parking lot and smiling" /> */}
                                        <Img imgStyle={{objectFit: 'cover'}} className="h-64 w-64 rounded-full xl:h-80 xl:w-80 border-8" fluid={story.storyImage.fluid} />

                                    </div>

                                    <div className="relative lg:mx-10">
                                        <svg className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50" stroke="currentColor" fill="none" viewBox="0 0 144 144">
                                            <path strokeWidth="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
                                        </svg>

                                        <h3 className="text-3xl ax-text-blue font-bold leading-none mb-3">{story.storyHeading}</h3>
                                        <blockquote>
                                            <div className="text-2xl leading-9 font-medium text-gray-900">

                                                <div dangerouslySetInnerHTML={{ __html: story.storyQuote }} >
                                                </div>
                                            </div>
                                            <footer className="mt-8">
                                                <div className="flex">
                                                    <div className="flex-shrink-0 lg:hidden">
                                                        {/* <img className="h-12 w-12 rounded-full object-cover" src={Singapore} alt="Singapore team standing on a parking lot and smiling" /> */}
                                                        <Img imgStyle={{objectFit: 'cover'}} className="h-12 w-12 rounded-full" fluid={story.storyImage.fluid} />
                                                    </div>
                                                    <div className="ml-4 lg:ml-0">
                                                        <div className="text-base leading-6 font-medium text-gray-900 font-bold">{story.storySellerName}</div>
                                                        <div className="text-base leading-6 font-medium text-blue-700">{story.storyNameByline}</div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </blockquote>





 {/* MODAL START */}
                                  <>
                                        <span className="inline-flex rounded-md mt-8">
                                            <button className="inline-flex justify-center py-2 px-4 border border-transparent text-lg leading-5 font-medium rounded-full text-white ax-bg-green hover:bg-green-500 focus:outline-none focus:border-green-600 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out" onClick={() => setOpenModal(true)}>
                                                {story.storyButtonText}
                                            </button>
                                        </span>


                                            <Modal open={openModal} onClose={() => setOpenModal(false)} center styles={{ modal: { maxWidth: '100%' } }}>
                                                <div className="relative rounded-lg flex items-center flex-col-reverse md:flex-row">

                                                    <div className="w-full md:w-3/5 h-full flex items-center md:bg-gray-100 bg-white flex ">

                                                        <div className="p-0 py-4 md:pr-24 md:pl-16 md:py-12">

                                                            <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{story.storyHeading}</h3>
                                                            <div className="text-gray-700 mb-8">


                                                                <div className="font-bold border-l-2 p-2 ax-text-blue text-xl" dangerouslySetInnerHTML={{ __html: story.storyQuote }} ></div><br />

                                                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: story.storyBody }} ></div>

                                                                <p className="text-lg font-bold">{story.storySellerName}, {story.storyNameByline}</p>


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="flex flex-1">
                                                        <svg className="z-20 hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12" viewBox="0 0 100 100" preserveAspectRatio="none">
                                                            <polygon points="50,0 100,0 50,100 0,100"></polygon>
                                                        </svg>
                                                    </div>

                                                    <div className="relative w-full md:w-2/5 h-full overflow-hidden flex">

                                                        {/* <img className="absolute inset-0 w-full h-full object-cover object-center relative" src={Singapore} alt="Singapore team standing next to each other on a parking lot" /> */}

                                                        <Img imgStyle={{objectFit: 'cover'}} className="absolute inset-0 w-full h-full object-center relative" fluid={story.storyImage.fluid} />
                                                    </div>

                                                </div>
                                            </Modal>

                                        </>

{/* MODAL END */}

                                    </div>
                                </div>
                            </div>
                        </section>

                    )
                })}

            </div>
        </section>
    )
}


export const query = graphql`
        fragment StoryFragment on DatoCmsHome {
            stories {
                storyBody
                storyButtonText
                storyHeading
                storyNameByline
                storyQuote
                storySellerName
                locale
                id
                storyImage {
                    fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsFluid
                    src                  
                }
              }
            }
            storiesHeading
        }
      `
