import React from "react"
import { graphql } from "gatsby"


export default function Hero(
  {
    data:
    {
      heroHeading,
      heroPreHeading,
      heroBody,
      heroVideoByline,
      heroVideo
    }
    
  }

) {
  return (
    <section className="relative bg-white overflow-hidden" id="section-start">
      <div className="hidden lg:block lg:absolute lg:inset-0">
        <svg className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="784" fill="none" viewBox="0 0 640 784">
          <defs>
            <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
          <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
        </svg>
      </div>
      <div className="relative max-w-6xl mx-auto pb-16 md:pb-20 lg:pb-24 xl:pb-30">

        <div className="pt-4 mt-20 md:mt-40 mx-auto max-w-6xl px-4 sm:px-6">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">

                {/* PRE HEADLINE */}
                {heroPreHeading}

              </div>

              {/* HEADLINE */}
              <h2 className="mt-1 text-3xl sm:text-5xl lg:text-5xl xl:text-5xl tracking-tight leading-10 font-extrabold ax-text-blue sm:leading-none">
                {heroHeading}
              </h2>

              {/* BODY TEXT */}
              <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" dangerouslySetInnerHTML={{ __html: heroBody }} >

              </div>

            </div>
            <div className="pt-0 md:pt-10 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center mx-auto">
              <svg className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden" width="640" height="784" fill="none" viewBox="0 0 640 784">
                <defs>
                  <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor" />
                <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
              <div className="relative mx-auto w-full  lg:max-w-md">
                <div className="relative block w-full shadow-lg overflow-hidden">

                  <video className="shadow-md outline-none" controls preload="none" poster={heroVideo.video.thumbPng}>

                    <source src={heroVideo.video.streamingUrl} type="application/x-mpegURL" />

                    <source src={heroVideo.video.mp4Med} type="video/mp4" />

                  </video>

                </div>

                <div className="text-gray-500 mx-auto text-sm w-full mt-2 text-center p-2 italic">

                  <svg className="inline mr-1" style={{ marginTop: '-1px' }} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.667 3h6.666C10.253 3 11 3.746 11 4.667v6.666c0 .92-.746 1.667-1.667 1.667H2.667C1.747 13 1 12.254 1 11.333V4.667C1 3.747 1.746 3 2.667 3z" />
                    <path d="M7.404 8.697l6.363 3.692c.54.313 1.233-.066 1.233-.697V4.308c0-.63-.693-1.01-1.233-.696L7.404 7.304a.802.802 0 0 0 0 1.393z" />
                  </svg>

                  {heroVideoByline}</div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
        fragment HeroFragment on DatoCmsHome {
            heroHeading
            heroPreHeading
            heroBody
            heroVideoByline
            heroVideo {
              video {
                streamingUrl
                mp4High: mp4Url(res: high)
                mp4Med: mp4Url(res: medium)
                mp4Low: mp4Url(res: low)
                duration
                thumbJpg: thumbnailUrl(format: jpg)
                thumbPng: thumbnailUrl(format: png)
                thumbGif: thumbnailUrl(format: gif)
              }
            }
        }
      `

