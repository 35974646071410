import React from "react"
import { graphql } from "gatsby"

import ClockIcon from "../images/icons/ax-clock.png";
import DealIcon from "../images/icons/ax-deal.png";
import ListIcon from "../images/icons/ax-list.png";

export default function Process(

    {
        data:
        {
            processHeading,
            processPreamble,
            step1PreHeading,
            step1Heading,
            step1Body,
            step2PreHeading,
            step2Heading,
            step2Body,
            step3PreHeading,
            step3Heading,
            step3Body
        },
      }

) {


    return (

      <section className="max-w-5xl mx-auto md:pt-16 pt-12 px-0 md:px-4" id="section-process">

        <h2 className="px-4 text-center mt-2 ax-text-blue text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10 mb-6">
          {processHeading}
               </h2>


        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl px-4 mb-10 max-w-4xl mx-auto text-left md:text-center">
          {processPreamble}
        </p>

        <div>
          <div className="flex flex-row">
            <div className="hidden md:flex flex-col items-center">
              <div className="w-32 h-32 py-5 border-8 bg-white rounded-full mr-4 uppercase flex flex-col items-center justify-center">

                <img src={ClockIcon} className="object-scale-down" alt="Clock icon" />

              </div>
              <div className="h-full border-l-4 border-transparent">
                <div className="border-l-4 mr-4 h-full border-dashed"></div>
              </div>
            </div>
            <div className="flex-auto border-8">
              <div className="flex md:flex-row flex-col items-center">

                <div className="flex-auto">

                  <div className="p-6 text-gray-100 ax-bg-blue">

                    <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300 uppercase">{step1PreHeading}</div>
                    <h2 className="mb-4 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">{step1Heading}</h2>

                    <div className="mt-3 text-gray-100" dangerouslySetInnerHTML={{ __html: step1Body }} >
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
          <div className="flex items-start flex-row">
            <div className="border-t-4 border-r-4 border-transparent">
              <div className="w-16 ml-16 h-16 border-l-4 border-dashed border-b-4 rounded-bl-full"></div>
            </div>
            <div className="border-t-4 border-transparent flex-auto">
              <div className="h-16 border-b-4 border-dashed"></div>
            </div>
            <div className="w-16 mt-16 mr-16 h-16 border-r-4 border-dashed border-t-4 rounded-tr-full"></div>
          </div>
          <div className="flex flex-row-reverse">
            <div className="hidden md:flex flex-col items-center">
              <div className="w-32 h-40 p-4 border-8 rounded-full bg-white ml-4 uppercase flex flex-col items-center justify-center">

                <img src={ListIcon} className="object-scale-down" alt="List icon" />

              </div>
              <div className="h-full border-r-4 border-transparent">
                <div className="border-l-4 ml-4 h-full border-dashed"></div>
              </div>
            </div>
            <div className="flex-auto border-8">
              <div className="flex md:flex-row flex-col items-center">
                <div className="flex-auto">
                  <div className="p-6 text-gray-100 ax-bg-green">
                    <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300 uppercase">{step2PreHeading}</div>
                    <h2 className="mb-4 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">{step2Heading}</h2>

                    <div className="mt-3 text-gray-100" dangerouslySetInnerHTML={{ __html: step2Body }} >
                    </div>

                  </div>
                </div>
                <div className="md:w-96 w-full p-5 hidden"><img src={ListIcon} className="object-scale-down" alt="List icon"/></div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-row-reverse">
            <div className="border-t-4 border-l-4 border-transparent">
              <div className="w-16 mr-16 h-16 border-r-4 border-dashed border-b-4 rounded-br-full"></div>
            </div>
            <div className="border-t-4 border-transparent flex-auto">
              <div className="h-16 border-b-4 border-dashed"></div>
            </div>
            <div className="w-16 mt-16 ml-16 h-16 border-l-4 border-dashed border-t-4 rounded-tl-full"></div>
          </div>
          <div className="flex flex-row">
            <div className="hidden md:flex flex-col items-center">
              <div className="w-32 h-32 p-5 border-8 bg-white rounded-full mr-4 uppercase flex flex-col items-center justify-center">
                <img src={DealIcon} className="object-scale-down" alt="Deal icon" />
              </div>
            </div>
            <div className="flex-auto border-8 ">
              <div className="flex md:flex-row flex-col items-center">
                <div className="flex-auto bg-gray-50">

                  <div className="p-6 ax-text-blue">

                    <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-500 uppercase">{step3PreHeading}</div>
                    <h2 className="mb-4 ax-text-blue text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">{step3Heading}</h2>
                    <div className="mt-3 text-gray-700" dangerouslySetInnerHTML={{ __html: step3Body }} >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

    )
}
export const query = graphql`
    fragment ProcessFragment on DatoCmsHome {
        processHeading
        processPreamble
        step1PreHeading
        step1Heading
        step1Body
        step2PreHeading
        step2Heading
        step2Body
        step3PreHeading
        step3Heading
        step3Body
    }
`