import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'


export default function About(
  {
    data:
    {
      aboutBody,
      aboutHeading,
      aboutPreHeading,
      aboutImage
    },
  }

) {
  return (
    <section className="relative ax-bg-blue max-w-6xl mx-auto mb-4 md:mb-6" id="section-be-a-part">
      <div className="h-56 bg-white border-l-8 border-r-8 border-t-8 border-b-0 md:border-b-8 p-2 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        {/* <img className="w-full h-full object-scale-down" src={WorldMap} alt="A world map" /> */}
        <Img imgStyle={{objectFit: 'contain'}} className="w-full h-full object-scale-down" fluid={aboutImage.fluid} />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-8 sm:px-6 lg:px-8 lg:py-16 border-8 md:border-0 border-t-0">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            {aboutPreHeading}
    </div>
          <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            {aboutHeading}
    </h2>

          <div className="mt-3 text-lg leading-7 text-gray-300" dangerouslySetInnerHTML={{ __html: aboutBody }} >
          </div>

        </div>
      </div>
    </section>
  )
}

export const query = graphql`
        fragment AboutFragment on DatoCmsHome {
            aboutBody
            aboutHeading
            aboutPreHeading
            aboutImage {
                fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
                ...GatsbyDatoCmsFluid
                src                  
            }
          }
        }
      `

