//import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import Scrollspy from 'react-scrollspy';
import LangSwitch from '../components/langSwitch';

import Logo from "../images/anticimex-logo.png";



function Header(
{
  data: {
    menuItem1,
    menuItem2,
    menuItem3,
    menuItem4,
    menuItem5,
    languageName,
    locale
  }
}
) {

  const currentLang = languageName
  const currentLocale = locale

  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  const [showText, setShowText] = useState(false);

  return (
    <header 
    className={scroll === false ? "fixed z-50 pt-2 pb-2 md:pt-2 mt:pb-2 bg-white border-gray-200 w-full shadow-sm" : "absolute w-full z-50 pt-6 pb-0 md:pt-14 md:pb-14"}
    id="nav">
      <nav className="relative max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-6">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">

                <div className="-mr-2 flex items-center md:hidden">
                  <button aria-label="Menu" onClick={() => setShowText(!showText)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                    <svg aria-hidden="true" className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>

                <img className="w-auto h-8 sm:h-auto max-h-8 mx-auto md:mx-0 md:block" src={Logo} alt="" />


              </div>
              <div className="hidden md:block md:mx-auto md:px-6 whitespace-pre">
              <Scrollspy items={ ['section-start', 'section-be-a-part', 'section-voices', 'section-process', 'section-contact'] } componentTag="span" currentClassName="border-b-2 border-b-2 border-gray-300">

                <span onClick={() => scrollTo('#section-start')} className="cursor-pointer font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{menuItem1}</span>
                <span onClick={() => scrollTo('#section-be-a-part')} className="cursor-pointer lg:ml-10 ml-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{menuItem2}</span>
                <span onClick={() => scrollTo('#section-voices')} className="cursor-pointer lg:ml-10 ml-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{menuItem3}</span>
                <span onClick={() => scrollTo('#section-process')} className="cursor-pointer lg:ml-10 ml-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{menuItem4}</span>
                <span onClick={() => scrollTo('#section-contact')} className="cursor-pointer lg:ml-10 ml-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">{menuItem5}</span>
              
              </Scrollspy> 
              </div>
            </div>

              <LangSwitch currentLang={currentLang} currentLocale={currentLocale} />
              
            {/* <div className="hidden md:block text-right">
              <span className="inline-flex rounded-full">
                <span className="inline-flex rounded-full shadow-xs">
                  <a href="https://www.anticimex.com" target="__new" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-6 font-medium rounded-full ax-text-blue bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Anticimex.com
              <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd" />
                    </svg>
                  </a>
                </span>
              </span>
            </div> */}


          </nav>

          {showText &&
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                  <div className="px-3 pt-6 flex -mt-1">
                    <div className="-mr-10 relative z-100 -mt-1">
                      {/* CLOSE */}
                      <button onClick={() => setShowText(!showText)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="w-full">
                      <img className="h-8 w-auto mx-auto" src={Logo} alt="" />
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 text-center">
                    <span onClick={() => { scrollTo('#section-start'); setShowText(!showText);}} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{menuItem1}</span>
                    <span onClick={() => { scrollTo('#section-be-a-part'); setShowText(!showText); }} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{menuItem2}</span>
                    <span onClick={() => { scrollTo('#section-voices'); setShowText(!showText); }} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{menuItem3}</span>
                    <span onClick={() => { scrollTo('#section-process'); setShowText(!showText); }} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{menuItem4}</span>
                    <span onClick={() => { scrollTo('#section-contact'); setShowText(!showText); }}className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">{menuItem5}</span>
                  </div>
                  <div>
        
                  </div>
                </div>
              </div>
            </div>
          }
       
    </header>

  );
}

export default Header;

export const query = graphql`
  fragment MenuFragment on DatoCmsSiteSetting {
      menuItem1
      menuItem2
      menuItem3
      menuItem4
      menuItem5
      ...CurrentLangFragment
  }
  `