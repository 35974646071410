import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import About from "../components/about"
import Stories from "../components/stories"
import Process from "../components/process"
import Header from "../components/header"
import Layout from "../components/layout"
import Contact from "../components/contact"
//import { data } from "autoprefixer"

function Home(props) {
  const post = props.data.home
  const settings = props.data.settings

  return (
    <Layout data={settings}>
      <Header data={settings} />
      <section className="main-body mx-2 md:mx-4">
        <Hero data={post} />
        <About data={post} />
        <Stories data={post} />
        <Process data={post} />
        <Contact data={post} />
      </section>
    </Layout>
    
  )
}

export default Home


export const pageQuery = graphql`
query($locale: String!) {
    home: datoCmsHome(locale: { eq: $locale }) {
      ...HeroFragment
      ...AboutFragment
      ...StoryFragment
      ...ProcessFragment
      ...ContactFragment
    }
    settings: datoCmsSiteSetting(locale: {eq: $locale}) {
      ...MenuFragment
      ...FooterFragment
    }
  }
`