import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import React from "react"
import { graphql } from "gatsby"
import axLogo from "../images/anticimex-logo.png"

function Layout(
  {children,
  data: {
      footerLinksCol1,
      footerLinksCol2,
      footerLinksCol3,
      columnOneHeading,
      columnTwoHeading,
      columnThreeHeading

  }
}
) {
  return (
    
    <div className="flex flex-col min-h-screen font-ax text-gray-900">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Anticimex - Mergers and acquisitions</title>
        </Helmet>

      <main className="flex-1 w-full pb-8 mx-auto md:pb-16">
        {children}
      </main>

      <footer className="bg-white w-full">
            <div className="container mx-auto max-w-5xl px-8">

              <div className="w-full flex flex-col md:flex-row py-6">

                <div className="flex-1 mb-6">

                  <a className="text-orange-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
 <img src={axLogo} className="w-32" alt="Anticimex logotype"></img>
                    </a>
                </div>


                <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">{columnOneHeading}</p>
                  <ul className="list-reset mb-6">

                  {footerLinksCol1.map(link => {
                    return(
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0" key={link.id}>
                        <a href={link.link} className="no-underline hover:underline text-gray-800 hover:text-orange-500">{link.linkText}</a>
                      </li>
                    )
                    })}

                  </ul>
                </div>
                <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">{columnTwoHeading}</p>
                  <ul className="list-reset mb-6">

                  {footerLinksCol2.map(link => {
                    return(
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0" key={link.id}>
                        <a href={link.link} className="no-underline hover:underline text-gray-800 hover:text-orange-500">{link.linkText}</a>
                      </li>
                    )
                    })}

                  </ul>
                </div>
                <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">{columnThreeHeading}</p>
                  <ul className="list-reset mb-6">

                  {footerLinksCol3.map(link => {
                    return(
                      <li className="mt-2 inline-block mr-2 md:block md:mr-0" key={link.id}>
                        <a href={link.link} className="no-underline hover:underline text-gray-800 hover:text-orange-500">{link.linkText}</a>
                      </li>
                    )
                    })}

                  </ul>
                </div>
              </div>
            </div>


          </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export const query = graphql`
  fragment FooterFragment on DatoCmsSiteSetting {
    footerLinksCol1 {
      link
      linkText
      id
    }
    footerLinksCol2 {
      link
      linkText
      id
    }
    footerLinksCol3 {
      link
      linkText
      id
    }
    columnOneHeading
    columnTwoHeading
    columnThreeHeading
  }
  `