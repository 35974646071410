import React from 'react';
import { graphql } from "gatsby"
import { navigate } from 'gatsby-link';
import { useForm } from "react-hook-form";
//import Img from 'gatsby-image'


function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}


export default function Contact(

    {
        data: {
            locale,
            contactHeading,
            contactPreamble,
            managerHeading,
            inputFieldsHeading,
            contactFirstName,
            contactEmail,
            contactCompany,
            contactLastName,
            contactMessage,
            contactPhoneNumber,
            contactSubmitButton,
            errorCompany,
            errorEmail,
            errorFirstName,
            errorLastName,
            errorMessage,
            errorPhoneNumber,
            errorManager,
            managers
        }
    }

) {

    const { errors, register, handleSubmit } = useForm();

    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const onSubmit = data => {

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': 'contact',
                ...state,
                data
            }),
        })
            .then(() => navigate(locale === 'en' ? '/' + "thanks/" : `/${locale}` + "/thanks/"))
            .catch((error) => alert(error))
    }


    return (

        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24" id="section-contact">

            <div className="relative max-w-xl mx-auto">
                <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
                    <defs>
                        <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
                    <defs>
                        <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl ax-text-blue leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">

                        <span>{contactHeading}</span>

                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                        {contactPreamble}
      </p>
                </div>
                
                <div className="mt-12">
                    <form name={"contact"+locale.toUpperCase()} method="post" action={locale === 'en' ? '/' : `/${locale}` + "/thanks/"} data-netlify="true" onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="form-name" value="contact" />
                        {/* sm:grid-cols-2 sm:col-gap-3 */}
                        <div className="text-base mb-4 leading-6 font-semibold uppercase tracking-wider text-gray-600 uppercase">{managerHeading}</div>
                        <div className="grid grid-cols-1 row-gap-3 mb-8">

                        {managers.map(manager => {
                        return(
                            <label key={manager.id}>
                                <input type="radio" name="manager" value={manager.managerEmail} onChange={handleChange} ref={register({ required: { value: true, message: errorManager} })} />
                                <div className="manager row-gap-1 col-span-3 md:col-span-1 cursor-pointer hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray active:bg-gray-300 transition duration-150 ease-in-out border rounded-lg shadow-sm">
                                    <div className="px-4 py-4 whitespace-no-wrap">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0 h-10 w-10">
                                                <img className="h-10 w-10 rounded-full" src={manager.managerImage.fluid.src} alt="Tony Hurst, Regional Manager of Asia" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="text-sm leading-5 font-bold text-gray-900">{manager.managerName}</div>
                                                <div className="text-sm leading-5 text-gray-600">{manager.managerRegion}</div>
                                            </div>
                                            <div className="right-0 mr-4 mx-auto">
                                                <svg className="check hidden" aria-hidden="true" focusable="false" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 26 26"><path d="M22.567 4.73l-1.795-1.219a1.09 1.09 0 0 0-1.507.287l-8.787 12.959l-4.039-4.039a1.09 1.09 0 0 0-1.533 0l-1.533 1.536a1.084 1.084 0 0 0 0 1.534L9.582 22c.349.347.895.615 1.387.615s.988-.31 1.307-.774l10.58-15.606a1.085 1.085 0 0 0-.289-1.505z" fill="#6cc7aa" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        )
                        })}


                            <span className="text-red-400">{errors.manager && errors.manager.message}</span>
                        </div>
                        <div className="text-base mb-4 leading-6 font-semibold uppercase tracking-wider text-gray-600 uppercase">{inputFieldsHeading}</div>
                        <div className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">

                            <div>
                                <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">{contactFirstName}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input name="first_name" id="first_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" onChange={handleChange} ref={register({ required: { value: true, message: errorFirstName } })} />
                                </div>
                                <span className="text-red-400">{errors.first_name && errors.first_name.message}</span>
                            </div>
                            <div>
                                <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">{contactLastName}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input name="last_name" id="last_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" onChange={handleChange} ref={register({ required: { value: true, message: errorLastName } })} />
                                </div>
                                <span className="text-red-400">{errors.last_name && errors.last_name.message}</span>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="company" className="block text-sm font-medium leading-5 text-gray-700">{contactCompany}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input name="company" id="company" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" onChange={handleChange} ref={register({ required: { value: true, message: errorCompany } })} />
                                </div>
                                <span className="text-red-400">{errors.company && errors.company.message}</span>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">{contactEmail}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input name="email" id="email" type="email" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" onChange={handleChange} ref={register({ required: { value: true, message: errorEmail } })} />
                                </div>
                                <span className="text-red-400">{errors.email && errors.email.message}</span>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="phone_number" className="block text-sm font-medium leading-5 text-gray-700">{contactPhoneNumber}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input name="phone_number" id="phone_number" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" placeholder="+1 (555) 987-6543" onChange={handleChange} ref={register({ required: { value: true, message: errorPhoneNumber } })} />
                                </div>
                                <span className="text-red-400">{errors.phone_number && errors.phone_number.message}</span>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-medium leading-5 text-gray-700">{contactMessage}</label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <textarea name="message" id="message" rows="4" className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150" onChange={handleChange} ref={register({ required: { value: true, message: errorMessage } })}></textarea>
                                </div>
                                <span className="text-red-400">{errors.message && errors.message.message}</span>
                            </div>




                            {Object.keys(errors).length > 0 && errors.constructor === Object && (

                                <div className="rounded-md bg-red-50 p-4 mt-1 sm:col-span-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <svg className="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm leading-5 font-bold text-red-800">
                                                {/* Seems like you missed {Object.keys(errors).length} thing(s) in the form: */}
Please have a look at the form again.
</h3>


                                            <ul className="mt-2 text-sm leading-5 text-red-700 list-disc">
                                                {Object.entries(errors).map(([index, result]) => {
                                                    return <li key={index}>{result.message}</li>
                                                })}
                                            </ul>

                                        </div>

                                    </div>
                                </div>
                            )}



                            <div className="sm:col-span-2">
                                <span className="w-full inline-flex rounded-md shadow-sm">
                                    <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ax-bg-blue hover:bg-blue-800 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition ease-in-out duration-150">
                                        {contactSubmitButton}
            </button>
                                </span>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>

    );
}

export const query = graphql`
        fragment ContactFragment on DatoCmsHome {
            locale

            contactHeading
            contactPreamble
            managerHeading
            inputFieldsHeading

            contactFirstName
            contactEmail
            contactCompany
            contactLastName
            contactMessage
            contactPhoneNumber
            contactSubmitButton

            errorCompany
            errorEmail
            errorFirstName
            errorLastName
            errorManager
            errorMessage
            errorPhoneNumber

            managers {
                managerEmail
                managerName
                managerRegion
                locale
                id
                managerImage {
                    fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
                    src                  
                }
              }
            }
        }
        
      `

